import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Pagina from '../components/Pagina'
import './convenio.css'

const Arraypbms = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.png', '8.png', '9.png', '10.png', '11.jpg', '12.jpg']
const ArraypbmCards = ['1.png', '2.png', '3.png', '4.png', '5.png']
const ArraypbmHealth = ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.png', '11.png', '12.png']

export default function conveniosParcerias () {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/convenios-parcerias/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
    }
  `
  )
  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <section id="image-convenio" className="position-relative">
        <div id="img" className="w-100"></div>
        <div className="container">
          <div className="row">
            <div id="figcaption" className="col-10 col-md-7 col-lg-5 p-md-0">
              <h1 className="theme-secundary ">DESCONTOS E PARCERIAS</h1>
              <h2 className="theme-primary">
                A Nova Farma busca sempre oferecer as melhores condições
                possíveis pra seus clientes{' '}
              </h2>
              <p className="text-left my-md-4">
                Por isso temos parcerias com os maiores convênios,
                <br className="d-md-none" /> laboratórios e indústrias para que
                você possa cuidar bem da sua família,
                <br className="d-md-none" /> mas sem deixar o bolso doente!
                <br className="d-md-none" /> Confira os programas disponíveis.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="main-imagem" className="submain">
        <div className="pbm-info theme--quaternary py-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="m-4 m-lg-5 theme-primary text-center title">
                  PROGRAMA DE BENEFÍCIOS DE MEDICAMENTO
                </h2>
                <p className="text-center">
                  O programa de benefícios de Medicamento ou PBM, oferece
                  descontos em mais de 3000 medicamentos, incluindo tanto as
                  marcas quanto os genéricos. Esse benefício é oferecido
                  diretamente pela indústrica ao consumidor através das
                  drogarias.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="items-list py-1">
        <div className="container">
          <div className="row">
            <div className="mt-4 mb-2  col-md-6 d-flex justify-content-center">
              <h2 className="theme-primary left-title d-flex flex-column justify-content-center title ">
                VEJA COMO É FACIL USAR
              </h2>
            </div>
            <div className="col-md-6">
              <p className="pt-md-3 pl-3">
                {' '}
                <span className="arrow-icon"></span> Vá a uma de nossas lojas e
                verifique se o medicamento que você utiliza está disponível
                através do programa.
              </p>
              <p className="pt-3 pt-md-2 pl-3">
                {' '}
                <span className="arrow-icon"></span>Dependendo do programa, caso
                ainda não esteja cadastrado, será necessário um rápidocadastro
              </p>
              <p className="pt-3 pt-md-2 pl-3">
                {' '}
                <span className="arrow-icon"></span>Pronto, já pode aproveitar
                os descontos!
              </p>
            </div>
            <div className="pbm-img mb-5" id="pbmGallery">
              {Arraypbms.map((i, index) => {
                return (
                  <div className="item" key={`${index}-pbmGallery`}>
                    <img
                      src={require(`../assets/images/pbms/${i}`).default}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <section id="cards">
        <div className="cards theme--quaternary py-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="m-4 m-lg-5 theme-primary text-center title">
                  COMO PARTICIPAR DO PROGRAMA DE CONVÊNIOS
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>
                  Algumas empresas oferem a seus colaboradores cartões de
                  benefícios de medicamentos, ou planos de medicamentos.
                </p>
                <p>Confira os cartões credenciados:</p>
                <div className="pbm-img mb-5" id="pbmHealthCards">
                  {ArraypbmCards.map((i, index) => {
                    return (
                      <div className="item" key={`${index}-pbmGallery`}>
                        <img
                          src={require(`../assets/images/health-cards/${i}`).default}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="healthPlans">
        <div className="health-plans">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="m-4 m-lg-5 theme-primary text-center title">
                  DESCONTOS PARA PLANOS DE SAÚDE
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>
                  A Nova Farma oferece descontos especiais para os usuários de
                  planos de saúde, ou mesmo o Sistema Único de Saúde (SUS).
                </p>
                <p>
                  Basta informar o numero da carteirinha ou CPF para aproveitar
                  os melhores preços!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="container">
                <div className="pbm-img mb-5" id="pbmHealthPlan">
                  {ArraypbmHealth.map((i, index) => {
                    return (
                      <div className="item" key={`${index}-pbmHealthPlan`}>
                        <img
                          src={require(`../assets/images/health-plans/${i}`).default}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
